<template>
  <b-container id="login-reset-container">
    <b-card bg-variant="light" class="align-items-center">
      <h1 class="text-center mb-5">{{ $t('passwordReset.headline') }}</h1>
      <p class="text-center">{{ $t('passwordReset.password-error') }}</p>
      <b-container class="container flex-column">
        <label for="newPass" class="sr-only" aria-hidden="true">{{ $t('passwordReset.new-password') }}</label>
        <b-input
          id="newPass"
          type="password"
          name="password"
          v-model="password"
          required
          autofocus
          v-bind:placeholder="$t('passwordReset.new-password')"
          autocomplete="new-password"
          minlength="8"
          maxlength="200"
          :state="password.length > 0 ? newPasswordIsValid : null"
        />
        <label for="verifyPass" class="sr-only" aria-hidden="true">{{ $t('passwordReset.confirm-password') }}</label>
        <b-input
          id="verifyPass"
          type="password"
          name="password"
          v-model="verifyPassword"
          class="mt-2"
          required
          v-bind:placeholder="$t('passwordReset.confirm-password')"
          autocomplete="new-password"
          minlength="8"
          maxlength="200"
          :state="password.length > 0 && verifyPassword.length > 0 ? newPasswordsAreMatchingAndValid : null"
        />
        <b-button variant="primary" v-on:click="resetPassword" class="w-100 mt-4 mb-2">{{
          $t('passwordReset.reset')
        }}</b-button>
      </b-container>
    </b-card>
  </b-container>
</template>

<script>
import { StringValidationUtil } from '@/util/StringValidationUtil'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'

export default {
  name: 'PasswordReset',
  mixins: [ApiMixin, NotificationMixin],
  data() {
    return {
      password: '',
      verifyPassword: ''
    }
  },
  computed: {
    newPasswordIsValid() {
      return StringValidationUtil.isValidPassword(this.password)
    },
    newPasswordsAreMatchingAndValid() {
      return this.password === this.verifyPassword && this.newPasswordIsValid
    }
  },
  methods: {
    resetPassword() {
      if (this.newPasswordsAreMatchingAndValid) {
        let bodyFormData = {}
        bodyFormData.newPassword = this.password
        bodyFormData.confNewPassword = this.verifyPassword
        bodyFormData.token = this.getURLParameter('token')
        let self = this
        this.postRequest(
          '/account/password/reset',
          bodyFormData,
          new RequestConfig()
            .onSuccess(() => {
              // show success message AFTER the successful redirect, otherwise it flashes only for a sec
              self.$router
                .push({ name: 'login' })
                .then(() => self.displaySuccess(self.$t('passwordReset.reset-successful')))
            })
            .onError(() => {
              self.displayError(self.$t('passwordReset.password-error'))
            })
        )
      } else {
        self.displayError(self.$t('passwordReset.password-error'))
      }
    },
    getURLParameter(param) {
      const query = window.location.search
      const params = new URLSearchParams(query)
      return params.get(param)
    }
  }
}
</script>
